import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.scss";
import bannerCard1 from "../../assets/frutas-verduras.png";
import bannerCard2 from "../../assets/Frutas-Verduras-Granos.png";
import bannerCard3 from "../../assets/huevos.png";
import bannerCard4 from "../../assets/Legumbres y Otros.png";
import otherCard1 from "../../assets/temp/cms-banner-1.jpg";
import otherCard2 from "../../assets/temp/cms-banner-2.jpg";
import otherCard3 from "../../assets/temp/cms-banner-3.jpg";
import { HeroSwiper } from "../../components/HeroSwiper/HeroSwiper";
import { TopSwiper
 } from "../../components/TopSwiper/TopSwiper";
import { SpecialSwiper } from "../../components/SpecialSwiper/SpecialSwiper";
import bannerCompras from "../../assets/temp/banner-compras.png";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";

export const Home = () => {
  AOS.init();

  return (
    <main className="main">
      <HeroSwiper />
      <section id="como-funciona" className="section section--steps">
        <div className="steps">
          <div className="steps__card">
            <div className="steps__card--title">
              <p>Suscribete</p>
            </div>
            <p className="steps__card--description">
            ¡Regístrate y adquiere una membresía para disfrutar de los mejores precios y ahorrar en tus compras.!
            </p>
          </div>
          {/* <div className="steps__card">
            <div className="steps__card--title">
              <p>Visitanos</p>
            </div>
            <p className="steps__card--description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="steps__card">
            <div className="steps__card--title">
              <p>Usa tu QR</p>
            </div>
            <p className="steps__card--description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div> */}
        </div>
      </section>

      <section
        className="section section--banner-cards"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <h3 style={{ color: '#4caf50', textDecoration: 'underline', marginLeft: '20px' }}>Categorías</h3>
        <ul className="banner-cards">
    <li>
      <Link to="/filtered-products/Frutas y Verduras">
        <div className="banner-card">
          <img src={bannerCard1} alt="" />
        </div>
      </Link>
    </li>
    <li>
      <Link to="/filtered-products/Granos, Cereales y Semillas">
        <div className="banner-card">
          <img src={bannerCard2} alt="" />
        </div>
      </Link>
    </li>
    <li>
      <Link to="/filtered-products/Huevos y Otras Proteinas">
        <div className="banner-card">
          <img src={bannerCard3} alt="" />
        </div>
      </Link>
    </li>
    <li>
      <Link to="/filtered-products/Otros Productos de Despensa">
        <div className="banner-card">
          <img src={bannerCard4} alt="" />
        </div>
      </Link>
    </li>
  </ul>
      </section>
      <section className="top-swiper-container">
        <TopSwiper />
      </section>
      {/* <section className="special-swiper-container">
        <SpecialSwiper />
      </section> */}
      {/* <section
        className="section section--other-products"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <ul className="banner-cards">
          <li>
            <div className="banner-card">
              <p>Frutas</p>
              <img src={otherCard1} alt="" />
            </div>
          </li>
          <li>
            <div className="banner-card">
              <p>Productos</p>
              <img src={otherCard2} alt="" />
            </div>
          </li>
          <li>
            <div className="banner-card">
              <p>Vegetales</p>
              <img src={otherCard3} alt="" />
            </div>
          </li>
        </ul>
      </section> */}
      <section className="local-image-section">
      <Divider variant="middle" style={{ width: '80%', margin: '20px auto' }} /> {/* Este es el divider */}
        <img src={bannerCompras} alt="Compras" className="local-image" />
      </section>
    </main>
  );
};
