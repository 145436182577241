import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/auth`; // Cambia la URL según tu configuración
export const loginApi = async (username, password) => {
  try {
    const response = await axios.post(`${ API }/login`, {username, password}, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${ API }/forgot-password`, {email}, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const resetPassword = async ( token, newPassword) => {
  try {
    const response = await axios.post(`${ API }/reset-password`, { token, newPassword}, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};