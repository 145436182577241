import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    Grid,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import { green } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { registerClient } from '../../client/apiClients';
import { getRegions } from '../../client/apiRegions';
import { getCities } from '../../client/apiCities';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import { loginApi } from "../../client/apiAuth";
import { useNavigate } from 'react-router-dom'; 
import jwtDecode from "jwt-decode";

export const RegisterForm = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    rut: '',
    email: '',
    direction: '',
    department: '', 
    additionalInfo: '', 
    phone: '',
    region_id: '',
    city_id: '',
    password: '',
    date_of_birthday: '', 
    acceptPromotions: false,
    accepTerms: false,
  });

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const regionsData = await getRegions();
        setRegions(regionsData);
      } catch (error) {
        console.error('Error fetching regions:', error);
        toast.error('Error fetching regions');
      }
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesData = await getCities();
        setCities(citiesData);
      } catch (error) {
        console.error('Error fetching cities:', error);
        toast.error('Error fetching cities');
      }
    };

    fetchCities();
  }, [formData.region_id]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await registerClient(formData);
      console.log('Registro exitoso:', result);

      setSuccessDialogOpen(true);

      setFormData({
        name: '',
        last_name: '',
        rut: '',
        email: '',
        direction: '',
        number: '',
        department: '',
        additionalInfo: '',
        phone: '',
        region_id: '',
        city_id: '',
        password: '',
        date_of_birthday: '',
        acceptPromotions: false,
        accepTerms: false,
      });
    } catch (error) {
      console.error('Error al registrar:', error);
      const message = error.response?.data?.message;
      switch (message) {
        case 'A client with this RUT already exists.':
          setErrorMessage('Ya existe un cliente con este RUT.');
          break;
        case 'Rut invalid':
          setErrorMessage('El RUT no es válido.');
          break;
        default:
          setErrorMessage('Error al registrar. Intenta nuevamente.');
      }
      setErrorDialogOpen(true);
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          mt: 4,
          mb: {
            xs: '5vh',
            md: '3vh',
            lg: '2vh',
          },
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Crea tu cuenta en Takayapp.cl
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Campos de texto */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Nombre"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Apellidos"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="RUT"
                name="rut"
                value={formData.rut}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Correo"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                type="email"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Teléfono"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                variant="outlined"
                type="tel"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>

                        {/* Teléfono y Fecha de Nacimiento */}
            

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Fecha de Nacimiento (Opcional)"
                name="date_of_birthday"
                type="date"
                value={formData.date_of_birthday}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            {/* Select de Región */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: green[500],
                  },
                  '&:hover fieldset': {
                    borderColor: green[700],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: green[500],
                  },
                  height: 40, // Asignamos la misma altura que los TextField
                },
              }}>
                <InputLabel>Región</InputLabel>
                <Select
                  name="region_id"
                  value={formData.region_id}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>Seleccione una región</em>
                  </MenuItem>
                  {regions.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Select de Comuna */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: green[500],
                  },
                  '&:hover fieldset': {
                    borderColor: green[700],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: green[500],
                  },
                  height: 40, // Asignamos la misma altura que los TextField
                },
              }}>
                <InputLabel>Comuna</InputLabel>
                <Select
                  required
                  name="city_id"
                  value={formData.city_id}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>Seleccione una comuna</em>
                  </MenuItem>
                  {cities.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Dirección */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Calle"
                name="direction"
                value={formData.direction}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Número"
                name="number"
                value={formData.number}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>

            {/* Select de Departamento o Casa */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: green[500],
                  },
                  '&:hover fieldset': {
                    borderColor: green[700],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: green[500],
                  },
                  height: 40, // Asignamos la misma altura que los TextField
                },
              }}>
                <InputLabel>Departamento o Casa</InputLabel>
                <Select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>Seleccione una opción</em>
                  </MenuItem>
                  <MenuItem value="departamento">Departamento</MenuItem>
                  <MenuItem value="casa">Casa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Campo de contraseña */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Contraseña"
                name="password"
                value={formData.password}
                onChange={handleChange}
                variant="outlined"
                type="password"
                InputProps={{
                  sx: {
                    height: 40,
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>


            {/* Indicaciones adicionales */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Indicaciones adicionales"
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={2}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: green[500],
                    },
                    '&:hover fieldset': {
                      borderColor: green[700],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: green[500],
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            {/* Checkbox de promociones */}
            <Grid item xs={12}>
              <FormControl component="fieldset" variant="standard">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acceptPromotions}
                      onChange={handleChange}
                      name="acceptPromotions"
                      sx={{
                        color: green[500],
                        '&.Mui-checked': {
                          color: green[700],
                        },
                      }}
                    />
                  }
                  label="Aceptar recibir información y promociones"
                />
              </FormControl>
            </Grid>

            {/* Checkbox de términos y condiciones */}
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                error={!formData.accepTerms}
                variant="standard"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.accepTerms}
                      onChange={handleChange}
                      name="accepTerms"
                      sx={{
                        color: green[500],
                        '&.Mui-checked': {
                          color: green[700],
                        },
                      }}
                    />
                  }
                  label="Acepto los términos y condiciones"
                />
                {!formData.accepTerms && (
                  <FormHelperText>
                    Debes aceptar los términos y condiciones
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: green[500],
                '&:hover': {
                  backgroundColor: green[700],
                },
              }}
            >
              Crear cuenta
            </Button>
          </Box>
        </form>
      </Box>

      {/* Error Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <Typography id="error-dialog-description">
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
          <Box display="flex" alignItems="center">
            <CheckCircleIcon color="success" sx={{ mr: 1 }} />
            Registro exitoso
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography id="success-dialog-description">
            ¡Registro exitoso! Ya puedes iniciar sesión.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              handleCloseSuccessDialog();
              navigate('/'); 
            }} 
            color="primary" 
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
