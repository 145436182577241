import React, { useState, useContext, useEffect } from "react";
import {
  IconButton,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  useMediaQuery,
  useTheme,
  Pagination,
  Checkbox,
  FormControlLabel,
  Divider,
  Drawer,
  Collapse,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import { getProducts, getProductsSubgroup } from "../../client/apiProducts";

export const FilteredProductsPage = () => {
  const { subGroupName, searchTerm } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { addToCart, removeFromCart, updateQuantity, cartItems } = useContext(CartContext);
  const { balance, setBalance } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allSubGroups, setAllSubGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const productsPerPage = 16;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts();
        const fetchedProducts = response.map((item) => ({
          id: item.id,
          name: item.name || "Producto Desconocido",
          price: parseInt(item.price),
          price_membership: parseInt(item.price_membership),
          image: item.url_img,
          brand: item.brand,
          category: item.category,
          subGroup: item.subGroup,
          subCategory: item.subCategory,
        }));
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getProductsSubgroup();
        setAllSubGroups(categories);

        const currentCategory = categories.find((category) =>
          Object.keys(category).includes(subGroupName)
        );

        if (currentCategory) {
          setSubcategories(currentCategory[subGroupName]);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    fetchCategories();
  }, [subGroupName]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get("searchTerm");
    if (subGroupName) {
      // Filtrar productos por subgrupo y subcategorías seleccionadas
      const filtered = products.filter((product) =>
        product.subGroup === subGroupName &&
        (selectedSubcategories.length > 0 ? selectedSubcategories.includes(product.subCategory) : true)
      );
      setFilteredProducts(filtered);
    } else if (searchTerm) {
      // Filtrar productos por searchTerm
      const filtered = products.filter((product) =>
        product.subCategory.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products); // Si no hay filtro, mostrar todos los productos
    }
  }, [products, subGroupName, location.search, selectedSubcategories]);

  useEffect(() => {
    // Filtrar productos por categorías seleccionadas
    if (selectedCategories.length > 0) {
      const filtered = products.filter((product) =>
        selectedCategories.includes(product.category) // Filtrar por categoría
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products); // Si no hay filtros, mostrar todos los productos
    }
  }, [selectedCategories, products]);

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCategories((prev) => [...prev, value]); // Agregar categoría seleccionada
    } else {
      setSelectedCategories((prev) => prev.filter((category) => category !== value)); // Eliminar categoría si se deselecciona
    }
  };

  const handleGroupToggle = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSubGroupChange = (newSubGroup) => {
    if (newSubGroup !== subGroupName) {
      navigate(`/filtered-products/${newSubGroup}`);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleAddToCart = (product, isMembership = true) => {
    const productPrice = product.price;
    const membershipPrice = product.price_membership;

    if (isMembership && balance < membershipPrice) {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
        return;
      } else {
        isMembership = false;
      }
    }

    const priceToUse = isMembership ? membershipPrice : productPrice;

    const productToAdd = {
      ...product,
      price: priceToUse,
      price_membership: membershipPrice,
      price_normal: productPrice,
      isMembership: isMembership,
      brand: product.brand,
      id: isMembership ? String(product.id) : String(product.id) + "_normal",
    };

    addToCart(productToAdd, 1);

    if (isMembership) {
      setBalance((prevBalance) => prevBalance - membershipPrice);
    }
  };

  const handleIncrease = (product) => {
    const membershipPrice = product.price_membership;
    const productPrice = product.price;

    const productMembershipId = String(product.id);
    const productNormalId = String(product.id) + "_normal";

    const productInCart = cartItems.find((item) => item.id === productMembershipId && item.isMembership);
    const normalProductInCart = cartItems.find((item) => item.id === productNormalId && !item.isMembership);

    if (balance >= membershipPrice) {
      if (productInCart) {
        updateQuantity(productMembershipId, productInCart.quantity + 1);
      } else {
        handleAddToCart(product, true);
      }
      setBalance((prevBalance) => prevBalance - membershipPrice);
    } else {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
      } else {
        if (normalProductInCart) {
          updateQuantity(productNormalId, normalProductInCart.quantity + 1);
        } else {
          handleAddToCart(product, false);
        }
      }
    }
  };

  const handleDecrease = (product) => {
    const membershipProductId = String(product.id);
    const normalProductId = String(product.id) + "_normal";

    let productInCart = cartItems.find((item) => item.id === normalProductId && !item.isMembership);

    if (!productInCart) {
      productInCart = cartItems.find((item) => item.id === membershipProductId && item.isMembership);
    }

    if (productInCart) {
      if (productInCart.quantity > 1) {
        updateQuantity(productInCart.id, productInCart.quantity - 1);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      } else {
        removeFromCart(productInCart.id);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      }
    }
  };

  const getProductQuantity = (productId) => {
    const productNormal = cartItems.find((item) => item.id === String(productId) + "_normal");
    const productMembership = cartItems.find((item) => item.id === String(productId) && item.isMembership);
    return {
      normal: productNormal ? productNormal.quantity : 0,
      membership: productMembership ? productMembership.quantity : 0,
      total: (productNormal ? productNormal.quantity : 0) + (productMembership ? productMembership.quantity : 0),
    };
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const handleAddAtNormalPrice = () => {
    if (selectedProduct) {
      handleAddToCart(selectedProduct, false);
      handleDialogClose();
    }
  };

  return (
    <section className="section section--products-top">
      {!isMobile && (
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap={2}
          mt={2}
          sx={{
            width: isMobile ? "100%" : "70%",
            margin: "auto",
            padding: "10px",
          }}
        >
          {allSubGroups.map((subgroupObj, idx) => {
            const subgroupName = Object.keys(subgroupObj)[0];
            const isAnyCategorySelected = subgroupObj[subgroupName].some((category) =>
              selectedCategories.includes(category)
            );
            return (
              <Button
                key={idx}
                variant={subgroupName === subGroupName || isAnyCategorySelected ? "contained" : "outlined"}
                color="success"
                sx={{
                  marginRight: 1,
                  minWidth: "120px", // Asegura un ancho mínimo consistente
                  flexGrow: 1, // Permite a los botones crecer para ocupar el espacio disponible
                  flexBasis: "calc(25% - 8px)", // Se asegura de que los botones se distribuyan uniformemente
                  maxWidth: "200px", // Define un ancho máximo
                  textAlign: "center",
                  backgroundColor: isAnyCategorySelected ? "green" : "white", // Cambia el color del fondo si hay categorías seleccionadas
                  color: isAnyCategorySelected ? "white" : "green", // Cambia el color del texto si hay categorías seleccionadas
                }}
                onClick={() => handleSubGroupChange(subgroupName)}
              >
                {subgroupName}
              </Button>
            );
          })}
        </Box>
      )}

      {isMobile && (
        <Box textAlign="left" mt={2} ml={2}>
          <Button
            variant="outlined"
            sx={{ backgroundColor: 'white', borderColor: 'green', color: 'green' }}
            startIcon={<FilterListIcon />}
            onClick={handleDrawerToggle}
          >
            Filtrar
          </Button>
          <Divider sx={{ mt: 5, width: '90%', margin: '0 1 auto' }} />
        </Box>
      )}

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            borderRadius: "0 10px 10px 0",
            padding: 2,
            mt: 8,
            marginTop: 12
          },
        }}
      >
        <Box width={250} role="presentation">
          <Typography variant="h6" gutterBottom>
            Filtros
          </Typography>
          {allSubGroups.map((subGroup, index) => {
            const groupName = Object.keys(subGroup)[0];
            const categories = subGroup[groupName];
            const isExpanded = expandedGroups[groupName] || false;

            return (
              <Box key={index} mb={1}>
                <Box display="flex" alignItems="center" onClick={() => handleGroupToggle(groupName)} sx={{ cursor: "pointer" }}>
                  <IconButton size="small" sx={{ color: "green" }}>
                    <ExpandMoreIcon style={{ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }} />
                  </IconButton>
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>{groupName}</Typography>
                </Box>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Box ml={4}>
                    {categories.map((category, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            value={category}
                            onChange={handleCategoryChange}
                            checked={selectedCategories.includes(category)} // Mantener estado del checkbox
                          />
                        }
                        label={category}
                      />
                    ))}
                  </Box>
                </Collapse>
              </Box>
            );
          })}
        </Box>
      </Drawer>

      <Box display="flex" justifyContent="center" mt={4} width={isMobile ? "80%" : "70%"} margin="auto">
        {!isMobile && (
          <Box width="20%" display="flex" flexDirection="column">
            <Typography variant="h6">Filtros</Typography>
            {allSubGroups.map((subGroup, index) => {
              const groupName = Object.keys(subGroup)[0];
              const categories = subGroup[groupName];
              const isExpanded = expandedGroups[groupName] || false;

              return (
                <Box key={index} mb={1}>
                  <Box display="flex" alignItems="center" onClick={() => handleGroupToggle(groupName)} sx={{ cursor: "pointer" }}>
                    <IconButton size="small" sx={{ color: "green" }}>
                      <ExpandMoreIcon style={{ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </IconButton>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>{groupName}</Typography>
                  </Box>
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <Box ml={4}>
                      {categories.map((category, idx) => (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              value={category}
                              onChange={handleCategoryChange}
                              checked={selectedCategories.includes(category)}
                            />
                          }
                          label={category}
                        />
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        )}

        <Box
          width={isMobile ? "100%" : "75%"}
          display="grid"
          gridTemplateColumns={isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)"}
          gap={2}
          mt={2}
        >
          {currentProducts.map((product) => {
            const { normal: quantityNormal, membership: quantityMembership, total: totalQuantity } = getProductQuantity(product.id);

            return (
              <Card key={product.id} className="products-top__card" sx={{ maxWidth: 200 }}>
                <CardMedia
                  component="img"
                  height="133"
                  image={product.image}
                  alt={product.name}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent>
                  <Typography variant="caption" color="textSecondary">
                    {product.brand}
                  </Typography>
                  <Typography variant="body2" fontSize="0.75rem" color="textPrimary" fontWeight="bold" mt={1}>
                    {product.name}
                  </Typography>
                  <Typography variant="body1" fontSize="1.1rem" color="green" fontWeight="bold" mt={1}>
                    ${product.price_membership.toLocaleString("es-CL")}{" "}
                    <span style={{ fontSize: "0.8rem", color: "gray" }}>Membresía</span>
                  </Typography>
                  <Typography variant="body1" fontSize="1rem" color="textPrimary" mt={1}>
                    ${product.price.toLocaleString("es-CL")}{" "}
                    <Typography component="span" fontSize="0.8rem" color="textSecondary">
                      precio normal
                    </Typography>
                  </Typography>
                  {quantityMembership > 0 && (
                    <Typography variant="body2" color="green">
                      Membresía: {quantityMembership}
                    </Typography>
                  )}
                  {quantityNormal > 0 && (
                    <Typography variant="body2" color="red">
                      Normal: {quantityNormal}
                    </Typography>
                  )}
                  {totalQuantity === 0 ? (
                    <Button onClick={() => handleAddToCart(product)} variant="contained" color="success" fullWidth sx={{ mt: 1 }}>
                      Agregar
                    </Button>
                  ) : (
                    <div className="quantity-selector" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                      <IconButton onClick={() => handleDecrease(product)} sx={{ color: "white", backgroundColor: "green", "&:hover": { backgroundColor: "#66bb6a" } }}>
                        <RemoveIcon />
                      </IconButton>
                      <Typography sx={{ width: "50px", textAlign: "center", backgroundColor: "#f5f5f5" }}>
                        {totalQuantity}
                      </Typography>
                      <IconButton onClick={() => handleIncrease(product)} sx={{ color: "white", backgroundColor: "green", "&:hover": { backgroundColor: "#66bb6a" } }}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination count={Math.ceil(filteredProducts.length / productsPerPage)} page={currentPage} onChange={handlePageChange} color="primary" />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          sx: {
            margin: isMobile ? "10px" : "32px",
            padding: isMobile ? "8px" : "16px",
            borderRadius: "12px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            maxWidth: isMobile ? "95%" : "600px", // Ancho máximo en vista móvil
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: isMobile ? "8px 16px" : "16px 24px",
            borderBottom: "1px solid #e0e0e0", // Añade un divisor inferior
          }}
        >
          <DialogTitle
            sx={{
              flexGrow: 1,
              fontSize: isMobile ? "0.9rem" : "1.25rem",
              textAlign: isMobile ? "center" : "left"
            }}
          >
            Saldo de membresía insuficiente
          </DialogTitle>
          {!isMobile && (
            <IconButton onClick={handleDialogClose} sx={{ color: "gray" }}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        <DialogContent
          sx={{
            padding: isMobile ? "8px 16px" : "16px 24px",
            textAlign: "center"
          }}
        >
          <DialogContentText sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}>
            Has alcanzado el límite de tu saldo de membresía. Obtén tu membresía y sigue disfrutando de grandes descuentos.
          </DialogContentText>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
            padding: isMobile ? "8px 16px" : "16px 24px",
            width: "100%"
          }}
        >
          <Button
            onClick={handleAddAtNormalPrice}
            sx={{
              color: "green",
              borderColor: "green",
              backgroundColor: "white",
              width: "100%",
              fontSize: isMobile ? "0.8rem" : "1rem",
              padding: isMobile ? "6px 12px" : "8px 16px",
            }}
            variant="outlined"
          >
            Comprar a precio normal
          </Button>
          <Link to="/membership" style={{ textDecoration: 'none', width: '100%' }}>
            <Button
              sx={{
                width: "100%",
                color: "white",
                backgroundColor: "green",
                fontSize: isMobile ? "0.8rem" : "1rem",
                padding: isMobile ? "6px 12px" : "8px 16px",
                "&:hover": {
                  backgroundColor: "#388e3c" // Mejor color de hover para vista móvil
                }
              }}
              variant="contained"
            >
              Obtener Membresía
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </section>
  );
};
