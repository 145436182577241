import React, { useState } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./HeroSwiper.scss";

import banner1 from "../../assets/swiper/Feria-sin-logo.png";
import ban from "../../assets/swiper/Acelgas-sin-logo.png";
import tomate from "../../assets/swiper/Tomate-logo.png";
import tierra from "../../assets/swiper/tierra.png";
//import MembershipModal from "../Membership/MembershipPage";  // Importa el MembershipModal


SwiperCore.use([Navigation]);

export const HeroSwiper = () => {

  const [openMembershipModal, setOpenMembershipModal] = useState(false);  

  const handleOpenMembershipModal = () => {
    setOpenMembershipModal(true);
  };
  
  const handleCloseMembershipModal = () => {
    setOpenMembershipModal(false);
  };
  

  return (
    <section className="section section__hero">
      <Swiper
        navigation={true}
        loop={true}
        centeredSlides={true}
        className="hero-swiper"
      >
       <SwiperSlide>
        <div className="hero__card-container">
          <img className="hero__img" src={banner1} alt="" />
          <div className="hero__card">
          <h3 className="hero__card--title">
            ¡El ahorro que buscas,<br />la calidad que te mereces!
          </h3>
            <p className="hero__card--description"></p>
            {/* Contenedor para los enlaces */}
            <div className="hero__card--links">
              <Link to="/register" className="hero__login">
                Registrarse
              </Link>
              <Link to="/membership" className="hero__login" onClick={handleOpenMembershipModal}>
                Membresia
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>

        <SwiperSlide>
          <div className="hero__card-container">
            <img className="hero__img" src={ban} alt="" />
            <div className="hero__card">
              <p className="hero__card--title">¡Ahorra en T 'akay!</p>
              <p className="hero__card--description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel in
                nulla sodales gravida elementum id. Dignissim cras nisl.
              </p>
              {/* <Link to="/iniciar-sesion" className="hero__login">
                Registrarse
              </Link> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="hero__card-container">
            <img className="hero__img" src={tomate} alt="" />
            <div className="hero__card">
              <p className="hero__card--title">¡Ahorra en T 'akay!</p>
              <p className="hero__card--description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel in
                nulla sodales gravida elementum id. Dignissim cras nisl.
              </p>
              {/* <Link to="/iniciar-sesion" className="hero__login">
                Registrarse
              </Link> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="hero__card-container">
            <img className="hero__img" src={tierra} alt="" />
            <div className="hero__card">
              <p className="hero__card--title">¡Ahorra en T 'akay!</p>
              <p className="hero__card--description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel in
                nulla sodales gravida elementum id. Dignissim cras nisl.
              </p>
              {/* <Link to="/iniciar-sesion" className="hero__login">
                Registrarse
              </Link> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {/* <MembershipModal
        open={openMembershipModal}
        onClose={handleCloseMembershipModal}
      /> */}
    </section>
  );
};
