import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import { updateSalesOrder } from "../../client/apiSalesOrder"; // Importa tu función para actualizar el estado

export const AdminPage = () => {
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");

  // Manejar el cambio de estado
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // Manejar el cambio de ID
  const handleOrderIdChange = (event) => {
    setOrderId(event.target.value);
  };

  // Función que envía los datos a la API
  const handleSubmit = async () => {
    if (!orderId || !status) {
      alert("Por favor, completa todos los campos.");
      return;
    }

    try {
      // Llamar a la API para actualizar el estado del pedido
      const response = await updateSalesOrder(orderId, status);

      if (response.success) {
        alert("Estado actualizado exitosamente.");
        // Limpiar los campos después de actualizar el estado correctamente
        setOrderId("");
        setStatus("");
      } else {
        alert("Error actualizando el estado.");
      }
    } catch (error) {
      console.error("Error al actualizar el estado:", error);
      alert("Error al actualizar el estado.");
    }
  };

  return (
    <Container
      sx={{
        mt: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: "bold",
          textAlign: "center",
          color: "green", // Color verde para el título
        }}
      >
        Cambiar Estado
      </Typography>
      <Card
        sx={{
          width: isMobile ? "100%" : "50%",
          padding: isMobile ? 2 : 4,
          boxShadow: 3,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <TextField
              label="ID del Pedido"
              variant="outlined"
              fullWidth
              value={orderId}
              onChange={handleOrderIdChange} // Manejar cambio de ID
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth>
              <InputLabel>Estado</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange} // Manejar cambio de estado
                label="Estado"
              >
                <MenuItem value={"Pendiente de pago"}>Pendiente de pago</MenuItem>
                <MenuItem value={"Pagado"}>Pagado</MenuItem>
                <MenuItem value={"En ruta"}>En ruta</MenuItem>
                <MenuItem value={"Domicilio sin moradores"}>Domicilio sin moradores</MenuItem>
                <MenuItem value={"En preparacion"}>En preparacion</MenuItem>
                <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
                <MenuItem value={"Reagendar"}>Reagendar</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit} // Llamar a la función al presionar el botón
              sx={{
                bgcolor: "green", // Color verde para el botón
                color: "white",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "darkgreen",
                },
              }}
            >
              Cambiar Estado
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
