import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material"; // Importar ícono de éxito
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import { CartContext } from '../../context/CartContext';
import { commitTransactionMembership } from '../../client/apiWebpayService';

export const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [membershipBalance, setMembershipBalance] = useState(null); // Estado para almacenar el nuevo saldo de membresía
  const { setBalance } = useContext(AuthContext); 
  const { setCartItems } = useContext(CartContext);

  // Función para ajustar los precios del carrito según el saldo disponible
  const adjustCartPrices = useCallback((newBalance, cartItems) => {
    let remainingBalance = newBalance;

    const updatedCartItems = cartItems.map(item => {
      const membershipPrice = parseInt(item.price_membership);
      if (!item.isMembership && remainingBalance >= membershipPrice * item.quantity) {
        remainingBalance -= membershipPrice * item.quantity;
        
        // Eliminar el sufijo '_normal' del id si aplica el precio de membresía
        let updatedId = item.id.includes("_normal") ? item.id.replace("_normal", "") : item.id;

        return {
          ...item,
          id: updatedId, // Actualiza el ID sin '_normal'
          price: membershipPrice,
          isMembership: true,
        };
      }
      return item;
    });

    setBalance(remainingBalance);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    localStorage.setItem("balance", remainingBalance);
  }, [setBalance, setCartItems]);

  // Función para calcular el total de los productos comprados a precio de membresía
  const calculateMembershipUsed = useCallback((cartItems) => {
    return cartItems.reduce((total, item) => {
      if (item.isMembership) {
        return total + (parseInt(item.price_membership) * item.quantity);
      }
      return total;
    }, 0);
  }, []);

  // Restaurar el carrito desde localStorage
  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (savedCartItems && savedCartItems.length > 0) {
      setCartItems(savedCartItems);
    } else {
      console.error("No se encontraron productos en el carrito.");
    }
  }, [setCartItems]);

  // Cargar el carrito y confirmar la transacción al montar el componente
  useEffect(() => {
    const processPayment = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token_ws");
      const membershipTypeId = parseInt(localStorage.getItem("membershipId"));
      const clientId = parseInt(localStorage.getItem("userId"));

      if (token) {
        try {
          // Llamada al commit de la transacción
          const response = await commitTransactionMembership(token, membershipTypeId, clientId);
          const responseData = response.data;

          if (responseData.status && responseData.status === "AUTHORIZED") {
            // Obtener el saldo de membresía desde la respuesta
            const fullBalance = responseData.balanceMembership;
            console.log('fullBalance', fullBalance)

            // Restaurar el carrito desde localStorage (por si se perdió en el contexto)
            const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));

            if (savedCartItems && savedCartItems.length > 0) {
              // Calcular el total usado en productos a precio de membresía
              const membershipUsed = calculateMembershipUsed(savedCartItems);

              // Ajustar el saldo restante después de descontar lo usado en productos a precio de membresía
              const remainingBalance = fullBalance - membershipUsed;
              setMembershipBalance(remainingBalance); // Almacenar el saldo de membresía actualizado

              // Ajustar precios del carrito si hay productos
              adjustCartPrices(remainingBalance, savedCartItems);
            } else {
              // Si no hay productos, solo actualizar el saldo completo
              setMembershipBalance(fullBalance);
              setBalance(fullBalance);
              localStorage.setItem("balance", fullBalance);
            }

            setSuccess(true); // Transacción exitosa
          } else {
            setError(true); // Error en la transacción
          }
        } catch (error) {
          console.error("Error al confirmar la transacción:", error);
          setError(true);
        } finally {
          setLoading(false); // Termina la carga
        }
      } else {
        setError(true);
        setLoading(false);
      }
    };

    processPayment();
  }, [location.search, adjustCartPrices, calculateMembershipUsed, setBalance]);

  const handleGoHome = () => {
    navigate("/"); // Redirige a la página principal
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      {loading ? (
        <CircularProgress /> 
      ) : success ? (
        <>
          <CheckCircleOutline sx={{ fontSize: 80, color: "green", mb: 2 }} /> {/* Ícono de éxito */}
          <Typography variant="h4" sx={{ mb: 2 }}>
            ¡Pago Exitoso!
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Tu pago ha sido procesado con éxito. ¡Gracias por tu compra!
          </Typography>
          {membershipBalance !== null && (
            <Typography variant="h6" sx={{ mb: 4 }}>
              Tu nuevo saldo de membresía es: ${membershipBalance.toLocaleString()} {/* Formato de miles */}
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ bgcolor: "green", color: "white", "&:hover": { bgcolor: "darkgreen" } }}
            onClick={handleGoHome}
          >
            Sigue disfrutando de tus descuentos 
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h4" sx={{ mb: 2, color: "red" }}>
            Error en el Pago
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Hubo un problema al procesar tu pago. Por favor, intenta de nuevo.
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "gray", color: "white", "&:hover": { bgcolor: "darkgray" } }}
            onClick={handleGoHome}
          >
            Volver a la Página Principal
          </Button>
        </>
      )}
    </Box>
  );
};
