import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  CardContent
} from "@mui/material";
import { addDays, format, nextDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import webpayLogo from '../../assets/logo-webpay-plus.png';

const timeZone = 'America/Santiago';

const PersonalData = ({
  clientData,
  addresses,
  selectedAddress,
  isNewAddressEnabled,
  newAddress,
  selectedComuna,
  comunas,
  deliveryDate,
  deliveryTime,
  setNewAddress,
  setSelectedComuna,
  setSelectedAddress,
  setIsNewAddressEnabled,
  setDeliveryDate,
  setDeliveryTime,
  paymentMethod,
  handlePaymentMethodChange,
  setShippingAmount
}) => {
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedComunaName, setSelectedComunaName] = useState(""); // Estado para almacenar el nombre de la comuna

  // Actualiza comuna y días disponibles al seleccionar una dirección
  const handleAddressChange = (event) => {
    const selectedAddressId = event.target.value;
    const selectedAddressObject = addresses.find(address => address.id === selectedAddressId);

    if (selectedAddressObject) {
      setSelectedAddress(selectedAddressObject.id); // Guarda el ID de la dirección
      setSelectedComuna(selectedAddressObject.city.id); // Guarda el ID de la comuna
      setSelectedComunaName(selectedAddressObject.city.name); // Guarda el nombre de la comuna
      updateAvailableDays(selectedAddressObject.city.name); // Actualiza los días disponibles de entrega
    }
  };

  // Manejo del checkbox para habilitar o deshabilitar la opción de nueva dirección
  const handleNewAddressToggle = (event) => {
    const isChecked = event.target.checked;
    setIsNewAddressEnabled(isChecked);

    if (isChecked) {
      setSelectedAddress('');
      setNewAddress('');
      setSelectedComuna('');
      setSelectedComunaName(''); // Resetear también el nombre de la comuna
    } else {
      setNewAddress('');
      const selectedAddressObject = addresses.find(address => address.id === selectedAddress);
      if (selectedAddressObject) {
        setSelectedComuna(selectedAddressObject.city.id);
        setSelectedComunaName(selectedAddressObject.city.name); // Almacena el nombre
        updateAvailableDays(selectedAddressObject.city.name);
      }
    }
  };

  // Lista de comunas y días de despacho
  const comunasDespacho = {
    "Maipu": ["MARTES", "JUEVES", "SÁBADO"],
    "Cerrillos": ["MARTES", "JUEVES", "SÁBADO"],
    "Pudahuel": ["MARTES", "JUEVES", "SÁBADO"],
    "Cerro Navia": ["SÁBADO"],
    "Lo Prado": ["SÁBADO"],
    "Quinta Normal": ["SÁBADO"],
    "Providencia": ["MARTES", "JUEVES", "SÁBADO"],
    "Vitacura": ["MARTES", "JUEVES", "SÁBADO"],
    "Lo Barnechea": ["MARTES", "JUEVES", "SÁBADO"],
    "Las Condes": ["MARTES", "JUEVES", "SÁBADO"],
    "La Reina": ["MARTES", "JUEVES", "SÁBADO"],
    "Peñalolén": ["MARTES", "JUEVES", "SÁBADO"],
    "La Florida": ["MARTES", "JUEVES", "SÁBADO"],
    "Ñuñoa": ["MARTES", "JUEVES", "SÁBADO"],
    "Macul": ["MARTES", "JUEVES", "SÁBADO"]
  };

  // Mapeo de días para los días de la semana
  const dayMapping = {
    "MARTES": 2,
    "JUEVES": 4,
    "SÁBADO": 6
  };

  const getNextValidDates = () => {
    const today = new Date();
    const nextTuesday = nextDay(today, 2);
    const nextThursday = nextDay(today, 4);
    const nextSaturday = nextDay(today, 6);
    return [nextTuesday, nextThursday, nextSaturday];
  };

  const updateAvailableDays = (comuna) => {
    const days = comunasDespacho[comuna] || [];

    const nextDates = getNextValidDates();

    const availableDates = days.map((day) => {
      const targetDay = dayMapping[day];
      const nextDate = nextDates.find(date => date.getDay() === targetDay);
      return { day, date: nextDate };
    });

    availableDates.sort((a, b) => a.date - b.date);

    const formattedDates = availableDates.map(({ day, date }) => ({
      day,
      date: formatInTimeZone(date, timeZone, 'yyyy-MM-dd')
    }));

    setAvailableDays(formattedDates);
    setShippingAmount(2900); // Establecemos el monto de envío
  };

  const handleDaySelection = (selectedDay) => {
    setDeliveryDate(selectedDay.date);
  };

  const handleComunaChange = (event) => {
    const selectedComunaName = event.target.value;
    setSelectedComunaName(selectedComunaName); // Almacena el nombre de la comuna

    const selectedComunaObject = comunas.find(comuna => comuna.name === selectedComunaName);
    if (selectedComunaObject) {
      setSelectedComuna(selectedComunaObject.id); // Almacenar el id de la comuna para la venta
      updateAvailableDays(selectedComunaName); // Actualizar los días disponibles en función del nombre
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto" }}>
      <Typography variant="h6" gutterBottom>Datos Personales</Typography>
      <Grid container spacing={2}>
        {/* Mostrar datos personales */}
        <Grid item xs={6}>
          <Typography variant="body1">Nombre:</Typography>
          <Typography variant="body2">{`${clientData?.name} ${clientData?.last_name}` || "No disponible"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">RUT:</Typography>
          <Typography variant="body2">{clientData?.rut || "No disponible"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Teléfono:</Typography>
          <Typography variant="body2">{clientData?.phone || "No disponible"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Correo:</Typography>
          <Typography variant="body2">{clientData?.email || "No disponible"}</Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>Dirección de Envío</Typography>
      <Select
        value={selectedAddress}
        onChange={handleAddressChange}
        displayEmpty
        fullWidth
        variant="outlined"
        sx={{ marginBottom: 2 }}
        disabled={isNewAddressEnabled}
      >
        <MenuItem value="">
          <em>Selecciona una dirección</em>
        </MenuItem>
        {addresses.map((address) => (
          <MenuItem key={address.id} value={address.id}>
            {address.address} - {address.city.name}
          </MenuItem>
        ))}
      </Select>

      <Box display="flex" alignItems="center" mb={2}>
        <Checkbox
          checked={isNewAddressEnabled}
          onChange={handleNewAddressToggle}
          color="primary"
        />
        <Typography variant="body1">Agregar nueva dirección</Typography>
      </Box>

      <TextField
        fullWidth
        label="Nueva dirección"
        variant="outlined"
        margin="normal"
        value={newAddress}
        onChange={(e) => setNewAddress(e.target.value)}
        sx={{ minHeight: 56 }}
        disabled={!isNewAddressEnabled}
      />

      <Select
        value={selectedComunaName} // Selecciona el nombre de la comuna
        onChange={handleComunaChange} // Manejar el cambio por nombre
        displayEmpty
        fullWidth
        variant="outlined"
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="">
          <em>Selecciona una comuna</em>
        </MenuItem>
        {comunas.map((comuna) => (
          <MenuItem key={comuna.id} value={comuna.name}> {/* Usamos comuna.name */}
            {comuna.name}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="h6" gutterBottom>Fechas de Entrega Disponibles</Typography>
      <Box display="flex" gap={2} mb={2}>
        {availableDays.length > 0 ? (
          availableDays.map((day, index) => (
            <Button
              key={index}
              variant={deliveryDate === day.date ? "contained" : "outlined"}
              onClick={() => handleDaySelection(day)}
            >
              {day.day} {day.date.split('-').reverse().join('/')}
            </Button>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            Selecciona una comuna para ver las fechas disponibles.
          </Typography>
        )}
      </Box>

      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>Selecciona un Método de Pago</Typography>
        <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
          <Card variant="outlined" sx={{ marginBottom: 2 }}>
            <CardContent>
              <FormControlLabel
                value="transfer"
                control={<Radio />}
                label={
                  <Typography variant="body1">
                    Transferencia Bancaria <br />
                    Realiza el pago directamente desde tu cuenta bancaria.
                  </Typography>
                }
              />
            </CardContent>
          </Card>
          
          <Card variant="outlined" sx={{ marginBottom: 2 }}>
            <CardContent>
              <FormControlLabel
                value="webpay"
                control={<Radio />}
                label={
                  <Box display="flex" alignItems="center">
                    <Box component="img"
                      src={webpayLogo} // Usamos el logo importado como fuente
                      alt="Webpay Logo"
                      sx={{ width: 200, height: 80, marginRight: 1 }} // Ajusta el tamaño y margen del logo
                    />
                    <Typography variant="body1">
                      Webpay <br />
                      Paga con tarjeta de crédito o débito a través de Webpay.
                    </Typography>
                  </Box>
                }
              />
            </CardContent>
          </Card>


        </RadioGroup>

    </Box>
  );
};

export default PersonalData;
