import React, { useState, useContext, useEffect } from "react";
import {
  IconButton,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  useMediaQuery,
  useTheme,
  Pagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import { getProducts } from "../../client/apiProducts";

export const TopSwiper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { addToCart, removeFromCart, updateQuantity, cartItems, setCartItems } = useContext(CartContext);
  const { balance, user, setBalance } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openMembershipModal, setOpenMembershipModal] = useState(false);
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts();
        const fetchedProducts = response.map((item) => ({
          id: item.id,
          name: item.name || "Producto Desconocido",
          price: parseInt(item.price),
          price_membership: parseInt(item.price_membership),
          image: item.url_img,
          brand: item.brand,
          category: item.category,
        }));
        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleAddToCart = (product, isMembership = true) => {
    const productPrice = product.price;
    const membershipPrice = product.price_membership;

    if (isMembership && balance < membershipPrice) {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
        return;
      } else {
        isMembership = false;
      }
    }

    const priceToUse = isMembership ? membershipPrice : productPrice;

    const productToAdd = {
      ...product,
      price: priceToUse,
      price_membership: membershipPrice,
      price_normal: productPrice,
      isMembership: isMembership,
      brand: product.brand,
      id: isMembership ? String(product.id) : String(product.id) + "_normal",
    };

    addToCart(productToAdd, 1);

    if (isMembership) {
      setBalance((prevBalance) => prevBalance - membershipPrice);
    }
  };

  const handleIncrease = (product) => {
    const membershipPrice = product.price_membership;
    const productPrice = product.price;

    const productMembershipId = String(product.id);
    const productNormalId = String(product.id) + "_normal";

    const productInCart = cartItems.find((item) => item.id === productMembershipId && item.isMembership);
    const normalProductInCart = cartItems.find((item) => item.id === productNormalId && !item.isMembership);

    if (balance >= membershipPrice) {
      if (productInCart) {
        updateQuantity(productMembershipId, productInCart.quantity + 1);
      } else {
        handleAddToCart(product, true);
      }
      setBalance((prevBalance) => prevBalance - membershipPrice);
    } else {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
      } else {
        if (normalProductInCart) {
          updateQuantity(productNormalId, normalProductInCart.quantity + 1);
        } else {
          handleAddToCart(product, false);
        }
      }
    }
  };

  const handleDecrease = (product) => {
    const membershipProductId = String(product.id);
    const normalProductId = String(product.id) + "_normal";

    let productInCart = cartItems.find((item) => item.id === normalProductId && !item.isMembership);

    if (!productInCart) {
      productInCart = cartItems.find((item) => item.id === membershipProductId && item.isMembership);
    }

    if (productInCart) {
      if (productInCart.quantity > 1) {
        updateQuantity(productInCart.id, productInCart.quantity - 1);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      } else {
        removeFromCart(productInCart.id);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      }
    }
  };

  const getProductQuantity = (productId) => {
    const productNormal = cartItems.find((item) => item.id === String(productId) + "_normal");
    const productMembership = cartItems.find((item) => item.id === String(productId) && item.isMembership);
    return {
      normal: productNormal ? productNormal.quantity : 0,
      membership: productMembership ? productMembership.quantity : 0,
      total: (productNormal ? productNormal.quantity : 0) + (productMembership ? productMembership.quantity : 0),
    };
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const handleAddAtNormalPrice = () => {
    if (selectedProduct) {
      handleAddToCart(selectedProduct, false);
      handleDialogClose();
    }
  };

  const handleOpenMembershipModal = () => {
    setOpenMembershipModal(true);
    handleDialogClose();
  };

  return (
    <section className="section section--products-top">
      <Box
        width="80%"
        margin="auto"
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)", // Dos columnas en pantallas móviles
          sm: "repeat(5, 1fr)", // Cinco columnas en pantallas más grandes
        }}
        gap={2}
        justifyContent="center"
        alignItems="center"
      >
        {currentProducts.map((product) => {
          const { normal: quantityNormal, membership: quantityMembership, total: totalQuantity } =
            getProductQuantity(product.id);

          return (
            <Card key={product.id} className="products-top__card" sx={{ maxWidth: 200 }}>
              <CardMedia
                component="img"
                height="133"
                image={product.image}
                alt={product.name}
                sx={{ objectFit: "cover" }}
              />
              <CardContent>
                {/* <Typography variant="caption" color="textSecondary">
                  {product.brand}
                </Typography> */}
                <Typography variant="body2" fontSize="0.75rem" color="textPrimary" fontWeight="bold" mt={1}>
                  {product.name}
                </Typography>
                <Typography variant="body1" fontSize="1.1rem" color="green" fontWeight="bold" mt={1}>
                  ${product.price_membership.toLocaleString("es-CL")}{" "}
                  <span style={{ fontSize: "0.8rem", color: "gray" }}>Membresía</span>
                </Typography>
                <Typography variant="body1" fontSize="1rem" color="textPrimary" mt={1}>
                  ${product.price.toLocaleString("es-CL")}{" "}
                  <Typography component="span" fontSize="0.8rem" color="textSecondary">
                    precio normal
                  </Typography>
                </Typography>

                {quantityMembership > 0 && (
                  <Typography variant="body2" color="green">
                    Membresía: {quantityMembership}
                  </Typography>
                )}
                {quantityNormal > 0 && (
                  <Typography variant="body2" color="red">
                    Normal: {quantityNormal}
                  </Typography>
                )}

                {totalQuantity === 0 ? (
                  <Button
                    onClick={() => handleAddToCart(product)}
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Agregar
                  </Button>
                ) : (
                  <div
                    className="quantity-selector"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleDecrease(product)}
                      sx={{
                        color: "white",
                        backgroundColor: "green",
                        "&:hover": {
                          backgroundColor: "#66bb6a",
                        },
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography
                      sx={{ width: "50px", textAlign: "center", backgroundColor: "#f5f5f5" }}
                    >
                      {totalQuantity}
                    </Typography>
                    <IconButton
                      onClick={() => handleIncrease(product)}
                      sx={{
                        color: "white",
                        backgroundColor: "green",
                        "&:hover": {
                          backgroundColor: "#66bb6a",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                )}
              </CardContent>
            </Card>
          );
        })}
      </Box>

      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={Math.ceil(filteredProducts.length / productsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Diálogo de saldo insuficiente */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth="sm"
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            margin: isMobile ? "10px" : "32px",
            padding: "16px",
            borderRadius: "12px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: "8px 16px" }}>
          <DialogTitle sx={{ flexGrow: 1, fontSize: "1rem" }}>Saldo de membresía insuficiente</DialogTitle>
          <IconButton onClick={handleDialogClose} sx={{ color: "gray" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem", textAlign: "center" }}>
            Has alcanzado el límite de tu saldo de membresía. Obtén tu membresía y sigue disfrutando de grandes descuentos.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
            padding: "16px",
          }}
        >
          <Button
            onClick={handleAddAtNormalPrice}
            sx={{
              color: "green",
              borderColor: "green",
              backgroundColor: "white",
              width: "100%",
            }}
            variant="outlined"
          >
            Comprar a precio normal
          </Button>
          <Button
            onClick={handleOpenMembershipModal}
            sx={{
              width: "100%",
              color: "white",
              backgroundColor: "green",
            }}
            variant="contained"
          >
            Obtener Membresía
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};
