import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { EditAddressModal }  from './EditAddressModal';
import { getCities } from '../../client/apiCities';
import { getRegions } from '../../client/apiRegions';

export const Addresses = ({ addresses }) => {
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  console.log(addresses)

  useEffect(() => {
    fetchRegions();
    fetchCities();
  }, []);

  const fetchRegions = async () => {
    const response = await getRegions();
    setRegions(response);
  };

  const fetchCities = async () => {
    const response = await getCities();
    setCities(response);
  };

  const handleEdit = (address) => {
    setSelectedAddress(address);
    setOpen(true);
  };

  const handleSave = (updatedAddress) => {
    console.log('Dirección actualizada:', updatedAddress);
    // Aquí puedes realizar la lógica para actualizar la lista de direcciones o refrescar la UI.
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Direcciones de Envío
      </Typography>
      <Grid container spacing={2}>
        {addresses.map((address, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                border: '1px solid #4caf50',
                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Dirección: {address.address}
                </Typography>
                <Typography variant="body1">Numero: {address.number}</Typography>
                <Typography variant="body1">Ciudad: {address.city}</Typography>
                <Typography variant="body1">Región: {address.region}</Typography>
                <Typography variant="body1">Entrega en: {address.department}</Typography>
                <Typography variant="body1">Informacion adicional: {address.additionalInfo}</Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => handleEdit(address)}>
                  Editar
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedAddress && (
        <EditAddressModal
          open={open}
          handleClose={() => setOpen(false)}
          address={selectedAddress}
          cities={cities}
          regions={regions}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};
